import React, { useEffect, useState } from "react";
import Nav from './components/Navbar';
import { Container, Button, MouseTrail, Spinner } from "retro-react";
import ChatGPT from "./components/ChatGPT";
import Tokenomics from "./components/Tokenomics";

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 500);
  })

  return (
    <>
      {loading ?
        <Container
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: "column",
            height: '100vh',
            justifyContent: 'center',
            width: '100vw',
            py: "20px",
            px: "20px"
          }}
        >
          <Spinner
            color="pastel"
            shape="circle"
            size="small"
          />
        </Container>
        :
        <div className="app">
          <Nav />
          <MouseTrail
            offset={{
              x: 0,
              y: 0
            }}
            particleColor="retro"
            particleSize={10}
          />
          <Container sx={{
            py: "20px",
            px: "20px",
            minWidth: "85%"
          }}>
            <ChatGPT />
            <div className="buttons">

              <a target="_blank" href="https://t.me/emule_sol">
                <Button
                  color="primary"
                  size="small"
                  variant="outline"
                  sx={{
                    my: 20
                  }}
                >

                  telegram
                </Button>
              </a>
              <a target="_blank" href="https://twitter.com/emule_sol">
                <Button
                  color="primary"
                  size="small"
                  variant="outline"
                  sx={{
                    my: 20,
                    mx: 2,
                  }}
                >

                  twitter
                </Button>
              </a>
              <a target="_blank" href="">
                <Button
                  color="primary"
                  size="small"
                  variant="filled"
                  sx={{
                    my: 20,
                  }}
                >

                  buy
                </Button>
              </a>
            </div>
            <Tokenomics />
          </Container >
        </div>
      }
    </>
  );
}

export default App;
