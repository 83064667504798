import React from 'react'
import { Text, LayoutMain, FlexibleLayout, LayoutFooter, LayoutHeader } from "retro-react";

export default function Tokenomics() {
    return (
        <div id='tokenomics'>
            <FlexibleLayout
                sx={{
                    '& .flexible-layout-child-cell': {
                        backgroundColor: 'lightgray',
                        border: '1px solid black'
                    }
                }}
            >
                <LayoutHeader>
                    <Text variant="h4">
                        Emule Tokenomic
                    </Text>
                </LayoutHeader>
                <LayoutMain>
                    <Text>
                        LP BURN and Mint authority revoked
                    </Text>
                </LayoutMain>

            </FlexibleLayout>
            <FlexibleLayout
                sx={{
                    '& .flexible-layout-child-cell': {
                        backgroundColor: 'lightgray',
                        border: '1px solid black'
                    }
                }}
            >
                <LayoutHeader>
                    <Text variant="h4">
                        Emule Supply
                    </Text>
                </LayoutHeader>
                <LayoutMain>
                    <Text>
                        100.000.000 in circulation
                    </Text>
                </LayoutMain>
            </FlexibleLayout>
            <FlexibleLayout
                sx={{
                    '& .flexible-layout-child-cell': {
                        backgroundColor: 'lightgray',
                        border: '1px solid black'
                    }
                }}
            >
                <LayoutHeader>
                    <Text variant="h4">
                        Emule CA
                    </Text>
                </LayoutHeader>
                <LayoutMain>
                    <Text>
                    7wyvEPeUFJRBkUPLsoqtGNrw4xcjan3WRWrUSt7AHKUU
                    </Text>
                </LayoutMain>
                <LayoutFooter>
                    <Text variant="paragraph">
                        <small>
                            © 1999 - 90s Nostalgia Zone
                        </small>
                    </Text>
                </LayoutFooter>
            </FlexibleLayout>
        </div>
    )
}
