import React, { useEffect, useState } from 'react'
import { Navbar, NavLogo, NavItem, Text } from "retro-react";

export default function Nav() {

    const [joke, setJoke ] = useState('');

    useEffect(() => {
        setTimeout(
            () => setJoke(''), 3000
        )
    })

  return (
    <Navbar
    color="primary"
    pattern="solid"
  >
    <NavLogo>
      <Text color="#fff">
        <strong>EMULE</strong> on solana
      </Text>
    </NavLogo>
    <NavItem>
      <a
        href=""
        onClick={() => setJoke("lol that's fake")}
      >
        {joke ? joke : 
        "Download"
        }
      </a>
    </NavItem>
  </Navbar>
  )
}
