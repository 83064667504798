import React, { useEffect, useState } from "react";
import { Box, Card, TypewriterText, Divider, Container, Text, LayoutMain, Input, Button, Spinner, FlexibleLayout, LayoutFooter, LayoutHeader, LayoutSidebarLeft, LayoutSidebarRight } from "retro-react";
import OpenAI from "openai";


const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
    organization: process.env.REACT_APP_ORGANIZATION,
});

export default function ChatGPT() {

    const [prompt, setPrompt] = useState('');
    const [emuleResponse, setEmuleResponse] = useState('');
    const [loading, setLoading] = useState('');

    const fetchOpenAI = async () => {
        setPrompt('');
        setLoading(true);
        try {
            const response = await openai.chat.completions.create({
                model: "gpt-3.5-turbo-1106",
                messages: [
                    {
                        role: "user",
                        content: `a fake fact about emule, the software dowloading generator from 2000's`,
                    },
                ],
            });
            setLoading(false);
            console.log(response.choices[0].message.content)
            setEmuleResponse(response.choices[0].message.content);

        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        console.log(prompt);
    })


    return (
        <div className="flex">
            <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Card
                    alt="Image from Picsum"
                    color="primary"
                    footer=""
                    header={<Text align="center" variant="h3">What is $EMULE ?</Text>}
                    image="/emule-vintage.png" 
                    
                    pattern="noise"
                    sx={{
                        maxHeight: '700px',
                        maxWidth: '100%'
                    }}
                >
                    {loading ?
                        <Spinner
                            color="primary"
                            shape="circle"
                            size="small"
                        />
                        :
                        <TypewriterText
                            sw={{ my: "20", height: "650px" }}
                            color="#000000"
                            text={emuleResponse}
                            typingSpeed={100}
                            variant="body1"
                        />
                    }
                </Card>
            </Box>
            <div className="form">

                <Button
                    color="primary"
                    onClick={() => fetchOpenAI()}
                    size="small"
                    variant="outlined"
                    sx={{
                        my: 20
                    }}
                >
                    Gimme emule fact
                </Button>
            </div>

        </div>
    )
}
